import { render, staticRenderFns } from "./BasePageHeading.vue?vue&type=template&id=eeb51c3c&"
import script from "./BasePageHeading.vue?vue&type=script&lang=js&"
export * from "./BasePageHeading.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\__PROJETCS\\vue\\likaliku\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('eeb51c3c')) {
      api.createRecord('eeb51c3c', component.options)
    } else {
      api.reload('eeb51c3c', component.options)
    }
    module.hot.accept("./BasePageHeading.vue?vue&type=template&id=eeb51c3c&", function () {
      api.rerender('eeb51c3c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/BasePageHeading.vue"
export default component.exports