

export default {
  'main': [
    {
      name: 'Dashboard',
      to: '/dashboard',
      icon: 'fas fa-th-large',
      permission: 'ALL',
      notifkey: null
    },

    {
      name: 'Kehadiran',
      to: '/attendance',
      key: 'attendance',
      icon: 'fas fa-user-clock',
      permission: 'USER_KEHADIRAN',
      notifkey: null
    },

    {
      name: 'Cuti',
      to: '/timeoff',
      icon: 'fas fa-business-time',
      permission: 'USER_CUTI',
      notifkey: null
    },

    {
      name: 'Reimburse',
      to: '/reimburse',
      key: 'reimburse',
      icon: 'fas fa-scroll',
      permission: 'USER_REIMBURSE',
      notifkey: 'MENU_REIMBURSE'
    },

    {
      name: 'Cash Advance',
      to: '/cashadvance',
      key: 'cashadvance',
      icon: 'fas fa-wallet',
      permission: 'USER_CASH_ADVANCE',
      notifkey: 'MENU_CASH_ADVANCE'
    },

    {
      name: 'Surat Tugas',
      to: '/surattugas',
      key: 'surattugas',
      icon: 'fas fa-envelope',
      permission: 'USER_SURAT_TUGAS',
      notifkey: 'MENU_LETTER',
    },

    {
      name: 'Approval',
      to: '/approval',
      icon: 'fas fa-check-square',
      permission: 'USER_APPROVAL',
      notifkey: 'MENU_APPROVAL'
    },

    {
      name: 'FAT',
      heading: true,
    },
    {
      name: 'Finance CR',
      to: '/finance/reimburse',
      icon: 'fas fa-chart-bar',
      permission: 'ADM_FINANCE_REIMBURSE_VIEW',
      notifkey: 'ADMIN_FINANCE_REIMBURSE'
    },

    {
      name: 'Finance CA',
      to: '/finance/cashadvance',
      icon: 'fas fa-cash-register',
      permission: 'ADM_FINANCE_CADVANCE_VIEW',
      notifkey: 'ADMIN_FINANCE_CADVANCE'
    },

    {
      name: 'Kasir Petty Cash',
      to: '/finance/kasir',
      icon: 'fas fa-cash-register',
      permission: 'ADM_CASHIER_PETTYCASH_VIEW',
      notifkey: 'ADMIN_KASIR_PETTYCASH'
    },

    {
      name: 'Payment Voucher',
      to: '/paymentvoucher',
      icon: 'fas fa-file-alt',
      permission: 'ADM_FINANCE_CADVANCE_VIEW',
      notifkey: 'ADMIN_PAYMENT_VOUCHER'
    },

    {
      name: 'TopUp Petty Cash',
      to: '/pettycash',
      icon: 'fas fa-donate',
      permission: 'ADM_CASHIER_PETTYCASH_TOPUP_VIEW',
      notifkey: 'ADMIN_TOPUP_PETTYCASH'
    },

    {
      name: 'HRGA',
      heading: true,
    },
    {
      name: 'Karyawan',
      to: '/employee',
      icon: 'fas fa-user-friends',
      permission: 'SETTING_EMPLOYEE_VIEW',
      notifkey: null
    },
    {
      name: 'Surat Tugas',
      to: '/surattugas/manage',
      icon: 'fas fa-envelope',
      permission: 'ASSIGNMENT_LETTER_VIEW',
      notifkey: null
    },
    {
      name: 'Laporan Kehadiran',
      to: '/attendance/admin/report',
      icon: 'fas fa-user-clock',
      permission: 'ATTENDANCE_REPORT_VIEW',
      notifkey: null
    },
    {
      name: 'Kehadiran Harian',
      to: '/attendance/admin/report2',
      icon: 'fas fa-user-clock',
      permission: 'DAILY_ATTENDANCE_VIEW',
      notifkey: null
    },
    {
      name: 'Manager On Duty',
      to: '/mod/',
      icon: 'fas fa-user-clock',
      permission: 'MANAGER_ON_DUTY_VIEW',
      notifkey: null
    },
    {
      name: 'Setting Kehadiran',
      to: '/attend/admin/setting',
      icon: 'fas fa-user-clock',
      permission: 'ATTENDANCE_SETTING',
      notifkey: null
    },

    {
      name: 'Operasional',
      heading: true,
    },
    {
      name: 'Vendor',
      to: '/vendor',
      icon: 'fas fa-warehouse',
      permission: 'VENDOR_VIEW',
      notifkey: null
    },
    {
      name: 'Asset',
      to: '/asset',
      icon: 'fas fa-box-open',
      permission: 'ASSET_VIEW',
      notifkey: null
    },
    {
      name: 'Maintenance',
      to: '/maintenance',
      icon: 'fas fa-tools',
      permission: 'MAINTENANCE_VIEW',
      notifkey: null
    },
    {
      name: 'Informasi',
      heading: true,
    },
    {
      name: 'Perusahaan',
      to: '/information/company',
      icon: 'fas fa-building',
      permission: 'INFO_COMPANY_VIEW',
      notifkey: null
    },
    {
      name: 'Kategori',
      to: '/information/category',
      icon: 'fas fa-clipboard-list',
      permission: 'INFO_CATEGORY_VIEW',
      notifkey: null
    },
    {
      name: 'Divisi',
      to: '/information/division',
      icon: 'fas fa-list-alt',
      permission: 'INFO_DIVISION_VIEW',
      notifkey: null
    },
    {
      name: 'Kontak Karyawan',
      to: '/information/contactnumber',
      icon: 'fas fa-user-friends',
      permission: 'USER_KONTAK_KARYAWAN',
      notifkey: null
    },
    {
      name: 'Pengumuman',
      to: '/announcement',
      icon: 'fas fa-bullhorn',
      permission: 'USER_PENGUMUMAN',
      notifkey: null
    },
    {
      name: 'Kalender',
      to: '/information/calendar',
      icon: 'fas fa-calendar-alt',
      permission: 'USER_KALENDER',
      notifkey: null
    },


    {
      name: 'Pengaturan',
      heading: true
    },
    {
      name: 'Perusahaan PIC',
      to: '/company/managerial',
      icon: 'fas fa-network-wired',
      permission: 'SETTING_MANAGERIAL_VIEW',
      notifkey: null
    },
    {
      name: 'Kategori PIC',
      to: '/company/category',
      icon: 'fas fa-clipboard-list',
      permission: 'SETTING_CATEGORY_VIEW',
      notifkey: null
    },
    {
      name: 'Petty Cash',
      to: '/company/pettycash',
      icon: 'fas fa-money-bill',
      permission: 'SETTING_PETTYCASH_VIEW',
      notifkey: null
    },
    {
      name: 'Bank Perusahaan',
      to: '/companybank',
      icon: 'fas fa-money-bill',
      permission: 'SETTING_COMPANY_BANK_VIEW',
      notifkey: null
    }
  ]
}
